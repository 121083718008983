
import { defineComponent, ref } from "vue";
// import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "kt-widget-6",
  components: {
    // Dropdown3,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const list = ref([
      {
        color: "warning",
        icon: "icons/duotune/abstract/abs027.svg",
        title: "Total bins ",
        text: "Ative Bins: 1000",
        text2: "None Active Bins: 120",
        number: "1120",
      },
      // {
      //   color: "success",
      //   icon: "icons/duotune/art/art005.svg",
      //   title: "Active Bins",
      //   // text: "Due in 2 Days",
      //   number: "1000",
      // },
      // {
      //   color: "danger",
      //   icon: "icons/duotune/communication/com012.svg",
      //   title: "Non Active Bins",
      //   // text: "Due in 5 Days",
      //   number: "120",
      // },
      {
        color: "info",
        icon: "icons/duotune/communication/com012.svg",
        title: "Total Cost",
        //  text: "Due in 7 Days",
        number: "300$",
      },
      {
        color: "info",
        icon: "icons/duotune/communication/com012.svg",
        title: "Average Fill Level  on Pickup",
        //  text: "Due in 7 Days",
        number: "89%",
      },
      {
        color: "info",
        icon: "icons/duotune/communication/com012.svg",
        title: "Total Carbon Foot Print",
        //  text: "Due in 7 Days",
        number: "1.11",
      },
      {
        color: "info",
        icon: "icons/duotune/communication/com012.svg",
        title: "Average PickUp time of Collection",
        //  text: "Due in 7 Days",
        number: "9.00",
      },
    ]);

    return {
      list,
    };
  },
});
